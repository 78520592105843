var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: { modal: false, visible: _vm.Deinfo, title: "商品拆装详情 " },
          on: {
            "update:visible": function ($event) {
              _vm.Deinfo = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { ref: "form", attrs: { model: _vm.form, inline: "" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "单号：", prop: "bill_no" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.bill_no,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bill_no", $$v)
                          },
                          expression: "form.bill_no",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "操作人：", prop: "add_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.add_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "add_name", $$v)
                          },
                          expression: "form.add_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "时间：", prop: "add_time" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "220px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.add_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "add_time", $$v)
                          },
                          expression: "form.add_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "移出仓库：", prop: "out_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.out_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "out_name", $$v)
                          },
                          expression: "form.out_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出库单：", prop: "out_code" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.out_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "out_code", $$v)
                          },
                          expression: "form.out_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.outData } },
                    [
                      _c("el-table-column", { attrs: { type: "index" } }),
                      _vm._l(_vm.colemd, function (list, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: list.label,
                            align: list.align,
                            prop: list.prop,
                            width: "",
                          },
                        })
                      }),
                    ],
                    2
                  ),
                  _c("br"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "移入仓库：", prop: "in_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.in_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "in_name", $$v)
                          },
                          expression: "form.in_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库单：", prop: "in_code" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.in_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "in_code", $$v)
                          },
                          expression: "form.in_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.inData } },
                    [
                      _c("el-table-column", { attrs: { type: "index" } }),
                      _vm._l(_vm.colemd1, function (list, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: list.label,
                            align: list.align,
                            prop: list.prop,
                            width: "",
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.Deinfo = !_vm.Deinfo
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }