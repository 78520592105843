<!--
 * @Author: your name
 * @Date: 2021-02-04 11:03:05
 * @LastEditTime: 2021-02-04 14:47:44
 * @LastEditors: Please set LastEditors
 * @Description: 详情
 * @FilePath: \sd-vue-admin\src\views\project\treasury\depotOperation\disassembly\components\Details.vue
-->
<template>
  <div>
    <el-dialog :modal="false" :visible.sync="Deinfo" title="商品拆装详情 ">
      <div>
        <el-form ref="form" :model="form" inline>
          <el-form-item label="单号：" prop="bill_no">
            <el-input v-model="form.bill_no" style="width: 220px" disabled />
          </el-form-item>
          <el-form-item label="操作人：" prop="add_name">
            <el-input v-model="form.add_name" style="width: 120px" disabled />
          </el-form-item>
          <el-form-item label="时间：" prop="add_time">
            <el-input v-model="form.add_time" style="width: 220px" disabled />
          </el-form-item>
          <el-form-item label="移出仓库：" prop="out_name">
            <el-input v-model="form.out_name" style="width: 120px" disabled />
          </el-form-item>
          <el-form-item label="出库单：" prop="out_code">
            <el-input v-model="form.out_code" style="width: 120px" disabled />
          </el-form-item>
          <br />
          <el-table stripe :data="outData">
            <el-table-column type="index"></el-table-column>
            <!-- <el-table-column type="selection" align="center"></el-table-column> -->
            <el-table-column
              v-for="(list, index) in colemd"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
            ></el-table-column>
          </el-table>
          <br />
          <el-form-item label="移入仓库：" prop="in_name">
            <el-input v-model="form.in_name" style="width: 120px" disabled />
          </el-form-item>
          <el-form-item label="入库单：" prop="in_code">
            <el-input v-model="form.in_code" style="width: 120px" disabled />
          </el-form-item>
          <br />
          <el-table stripe :data="inData">
            <el-table-column type="index"></el-table-column>
            <!-- <el-table-column type="selection" align="center"></el-table-column> -->
            <el-table-column
              v-for="(list, index) in colemd1"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
            ></el-table-column>
          </el-table>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="Deinfo = !Deinfo">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  export default {
    data() {
      return {
        Deinfo: false,
        form: {
          id: '',
          bill_no: '',
          out_name: '',
          in_name: '',
          add_time: '',
          add_name: '',
          diff_amount: '',
          in_amount: '',
          out_amount: '',
          in_code: '',
          out_code: '',
        },
        outData: [],
        inData: [],
        colemd: [
          {
            order: 1,
            label: '拆装前商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 2,
            label: '规格',
            align: 'center',
            prop: 'goods_spec',
            width: '',
          },
          {
            order: 3,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 3,
            label: '数量',
            align: 'center',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 3,
            label: '金额',
            align: 'center',
            prop: 'total_price',
            width: '',
          },
        ],
        colemd1: [
          {
            order: 1,
            label: '拆装前商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 2,
            label: '规格',
            align: 'center',
            prop: 'goods_spec',
            width: '',
          },
          {
            order: 3,
            label: '单价',
            align: 'center',
            prop: 'goods_price',
            width: '',
          },
          {
            order: 3,
            label: '数量',
            align: 'center',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 3,
            label: '金额',
            align: 'center',
            prop: 'total_price',
            width: '',
          },
        ],
        url: {
          info: '/depotAdmin/split/info',
        },
      }
    },
    watch: {
      Deinfo(val) {
        if (val == false) {
          this.$refs['form'].resetFields()
        }
      },
    },
    methods: {
      handlerinfo(row) {
        postAction(this.url.info, { id: row.id })
          .then((res) => {
            console.log(res)
            this.form.id = res.data.id
            this.form.bill_no = res.data.bill_no
            this.form.out_name = res.data.out_name
            this.form.in_name = res.data.in_name
            this.form.add_time = res.data.add_time
            this.form.add_name = res.data.add_name
            this.form.diff_amount = res.data.diff_amount
            this.form.in_amount = res.data.in_amount
            this.form.out_amount = res.data.out_amount
            this.form.in_code = res.data.in_code
            this.form.out_code = res.data.out_code
            this.outData = res.data.out_data
            this.inData = res.data.in_data
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
