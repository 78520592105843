var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "添加商品拆装",
        visible: _vm.showDialog,
        width: "1100px",
        top: "5vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "orderTest-container",
          attrs: { inline: "", "label-width": "80px" },
        },
        [
          _c("el-card", { staticClass: "before" }, [
            _c(
              "div",
              { staticClass: "tit" },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "line-height": "32px",
                      "font-size": "22px",
                      "margin-right": "15px",
                    },
                  },
                  [_vm._v(" 拆装前 ")]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "移出仓库", prop: "" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "移出仓库" },
                        model: {
                          value: _vm.form.out_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "out_id", $$v)
                          },
                          expression: "form.out_id",
                        },
                      },
                      _vm._l(_vm.depotSelect, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.depot_name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAdd(1)
                          },
                        },
                      },
                      [_vm._v("添加")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(1)
                          },
                        },
                      },
                      [_vm._v(" 更新库存 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table" },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      stripe: "",
                      data: _vm.beforeList,
                      "show-summary": "",
                      "summary-method": _vm.beforeSummary,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", type: "index", width: "50" },
                    }),
                    _vm._l(_vm.columns, function (item, tableIndex) {
                      return _c("el-table-column", {
                        key: tableIndex,
                        attrs: {
                          prop: item.prop,
                          label: item.label,
                          width: item.width,
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var row = ref.row
                                return [
                                  item.label == "商品名称"
                                    ? _c(
                                        "div",
                                        [
                                          _c("goods-search", {
                                            ref: "goodsSeachBefore",
                                            refInFor: true,
                                            attrs: {
                                              "show-kouwei": false,
                                              "depot-id": _vm.form.out_id,
                                              "f-key": row.goods_name,
                                            },
                                            on: {
                                              "add-rows": function ($event) {
                                                return _vm.addRows(
                                                  $event,
                                                  row,
                                                  $index,
                                                  1
                                                )
                                              },
                                              "select-goods-all": function (
                                                $event
                                              ) {
                                                return _vm.selectGoods(
                                                  $event,
                                                  row,
                                                  1
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : item.label == "单位"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "单位" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeUnit(
                                                    $event,
                                                    row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row.unit_id,
                                                callback: function ($$v) {
                                                  _vm.$set(row, "unit_id", $$v)
                                                },
                                                expression: "row.unit_id",
                                              },
                                            },
                                            _vm._l(
                                              row.arr_unit,
                                              function (i, idx) {
                                                return _c("el-option", {
                                                  key: idx,
                                                  attrs: {
                                                    value: i.unit_id,
                                                    label: i.unit_name,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : item.label == "数量"
                                    ? _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: row.unit_num,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_num", $$v)
                                              },
                                              expression: "row.unit_num",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : item.label == "单价"
                                    ? _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: row.goods_price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "goods_price",
                                                  $$v
                                                )
                                              },
                                              expression: "row.goods_price",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : item.label == "金额"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                Number(row.goods_price || 0) *
                                                Number(row.unit_num || 0)
                                              ).toFixed(2) || 0
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("div", [
                                        _vm._v(_vm._s(row[item.prop])),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var $index = ref.$index
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(1, $index)
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("el-card", { staticClass: "after" }, [
            _c(
              "div",
              { staticClass: "tit" },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "line-height": "32px",
                      "font-size": "22px",
                      "margin-right": "15px",
                    },
                  },
                  [_vm._v(" 拆装后 ")]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "移入仓库", prop: "" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "移入仓库" },
                        model: {
                          value: _vm.form.in_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "in_id", $$v)
                          },
                          expression: "form.in_id",
                        },
                      },
                      _vm._l(_vm.depotSelect, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.depot_name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleAdd(2)
                          },
                        },
                      },
                      [_vm._v("添加")]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "", prop: "" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(2)
                          },
                        },
                      },
                      [_vm._v(" 更新库存 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "table" },
              [
                _c(
                  "el-table",
                  {
                    attrs: {
                      stripe: "",
                      data: _vm.afterList,
                      "show-summary": "",
                      "summary-method": _vm.afterSummary,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { align: "center", type: "index", width: "50" },
                    }),
                    _vm._l(_vm.columns, function (item, tableIndex) {
                      return _c("el-table-column", {
                        key: tableIndex,
                        attrs: {
                          prop: item.prop,
                          label: item.label,
                          width: item.width,
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var $index = ref.$index
                                var row = ref.row
                                return [
                                  item.label == "商品名称"
                                    ? _c(
                                        "div",
                                        [
                                          _c("goods-search", {
                                            ref: "goodsSeachBefore",
                                            refInFor: true,
                                            attrs: {
                                              "show-kouwei": false,
                                              "depot-id": _vm.form.in_id,
                                              "f-key": row.goods_name,
                                            },
                                            on: {
                                              "add-rows": function ($event) {
                                                return _vm.addRows(
                                                  $event,
                                                  row,
                                                  $index,
                                                  2
                                                )
                                              },
                                              "select-goods-all": function (
                                                $event
                                              ) {
                                                return _vm.selectGoods(
                                                  $event,
                                                  row,
                                                  2
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : item.label == "单位"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: { placeholder: "单位" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeUnit(
                                                    $event,
                                                    row
                                                  )
                                                },
                                              },
                                              model: {
                                                value: row.unit_id,
                                                callback: function ($$v) {
                                                  _vm.$set(row, "unit_id", $$v)
                                                },
                                                expression: "row.unit_id",
                                              },
                                            },
                                            _vm._l(
                                              row.arr_unit,
                                              function (i, idx) {
                                                return _c("el-option", {
                                                  key: idx,
                                                  attrs: {
                                                    value: i.unit_id,
                                                    label: i.unit_name,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : item.label == "数量"
                                    ? _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: row.unit_num,
                                              callback: function ($$v) {
                                                _vm.$set(row, "unit_num", $$v)
                                              },
                                              expression: "row.unit_num",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : item.label == "单价"
                                    ? _c(
                                        "div",
                                        [
                                          _c("el-input", {
                                            model: {
                                              value: row.goods_price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  row,
                                                  "goods_price",
                                                  $$v
                                                )
                                              },
                                              expression: "row.goods_price",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : item.label == "金额"
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                Number(row.goods_price || 0) *
                                                Number(row.unit_num || 0)
                                              ).toFixed(2) || 0
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _c("div", [
                                        _vm._v(_vm._s(row[item.prop])),
                                      ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (ref) {
                            var $index = ref.$index
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(2, $index)
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "拆装差额:", prop: "" } },
            [
              _c("span", { staticStyle: { "margin-right": "10px" } }, [
                _vm._v(
                  " " +
                    _vm._s((_vm.afterTotal - _vm.beforeTotal).toFixed(2)) +
                    " "
                ),
              ]),
              _c("span", { staticStyle: { "margin-right": "10px" } }, [
                _vm._v("将计入商品拆分收入"),
              ]),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "拆装差额 = 拆装后金额合计 - 拆装前金额合计",
                    placement: "top",
                  },
                },
                [
                  _c("span", [
                    _c("i", {
                      staticClass: "el-icon-warning",
                      staticStyle: {
                        "font-size": "18px",
                        "line-height": "20px",
                      },
                    }),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v(" 提交并生成拆装出入库单 ")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.showDialog = false
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }