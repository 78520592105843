<!--
 * @Author: 候怀烨
 * @Date: 2021-02-01 15:02:42
 * @LastEditTime: 2021-03-26 09:38:27
 * @LastEditors: Please set LastEditors
 * @Description: 商品拆装
 * @FilePath: \sd-vue-admin\src\views\project\treasury\depotOperation\disassembly\index.vue
-->
<template>
  <!-- NAME[epic=库管] 商品拆装 -->
  <div style="padding: 20px">
    <el-row>
      <el-col :span="22">
        <el-form ref="ruleForm" inline :model="form">
          <el-form-item prop="out_id">
            <el-select
              v-model="form.out_id"
              placeholder="移出仓库"
              style="width: 120px"
            >
              <el-option
                v-for="(item, index) in warehouse"
                :key="index"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="in_id">
            <el-select
              v-model="form.in_id"
              placeholder="移入仓库"
              style="width: 120px"
            >
              <el-option
                v-for="(item, index) in warehouse"
                :key="index"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="add_id">
            <el-select
              v-model="form.add_id"
              placeholder="选择操作人"
              style="width: 120px"
            >
              <el-option
                v-for="(item, index) in personnel"
                :key="index"
                :label="item.user_name"
                :value="item.user_id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="value1">
            <el-date-picker
              v-model="value1"
              style="width: 220px"
              type="datetimerange"
              start-placeholder="起始时间"
              end-placeholder="结束时间"
              :default-time="['12:00:00']"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="handlerTiem"
            ></el-date-picker>
          </el-form-item>
          <el-form-item prop="goods_name">
            <!-- <goods-search
              ref="goodsSearchTable"
              :f-key="row.goods_name"
              @add-rows="addRows($event, $index)"
              @select-goods="selectGoodsIdT($event, row)"
              @select-goods-all="selectGoodsT($event, row)"
            ></goods-search> -->
            <el-input
              v-model="form.goods_name"
              style="width: 120px"
              placeholder="商品名称，简拼"
              suffix-icon="el-icon-search"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handlerInquire">查 询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="info" @click="handlerReset">重 置</el-button>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2">
        <el-form inline>
          <el-form-item>
            <el-button type="primary" @click="handlerAdd">
              添加商品拆装
            </el-button>
          </el-form-item>
          <!-- <el-form-item>
            <el-button>导入</el-button>
          </el-form-item>
          <el-form-item>
            <el-button>导出</el-button>
          </el-form-item> -->
        </el-form>
      </el-col>
    </el-row>
    <el-row>
      <el-table stripe :data="tableData">
        <el-table-column type="index"></el-table-column>
        <!-- <el-table-column type="selection" align="center"></el-table-column> -->
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
        <el-table-column label="操作" align="center" width="200" fixed="right">
          <template #default="{ row }">
            <!-- <el-button type="text" @click="handlershenhe(row)">审核</el-button> -->
            <!-- <el-button type="text" @click="handlerredo(row)">作废</el-button> -->
            <el-button type="text" @click="handlerlink(row)">查看</el-button>
            <el-popconfirm
              title="您真的要作废这个拆装商品吗？"
              @confirm="handlerstorp(row)"
            >
              <el-button
                v-show="row.status != 5"
                slot="reference"
                style="margin-left: 10px"
                type="text"
              >
                作废
              </el-button>
            </el-popconfirm>
            <el-button
              style="margin-left: 10px"
              type="text"
              @click="handlePrint(row)"
            >
              打印
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
    <!-- <details ref="info"></details> -->
    <info-details ref="info"></info-details>
    <add-disassembly
      ref="add"
      @refresh="handlergetlist"
      @getlist="handlergetlist"
    ></add-disassembly>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="CZ"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  // import GoodsSearch from '@/baseComponents/goodsSearch'
  import AddDisassembly from './components/addGoods.vue'
  import InfoDetails from './components/Details'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  export default {
    components: {
      AddDisassembly,
      InfoDetails,
      RuilangDisplayDialog,
    },
    data() {
      return {
        data_id: 0,
        value1: [],
        form: {
          pageNo: 1,
          pageSize: 10,
          in_id: '', //移入仓库
          out_id: '', //移出仓库
          add_id: '', //操作人id
          start_time: '', //'',//开始时间
          end_time: '', //结束时间
          goods_name: '', //商品名称关键字
          goods_id: '', //商品id
        },
        tableData: [],
        total: 0,
        warehouse: [],
        personnel: [],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          personnel: '/baseAdmin/common/user-list',
          list: '/depotAdmin/split/list',
          stop: '/depotAdmin/split/out',
        },
        colemd: [
          {
            label: '单号',
            align: 'center',
            prop: 'bill_code',
            width: '',
          },
          {
            label: '移出仓库',
            align: 'center',
            prop: 'out_name',
            width: '',
          },
          {
            label: '移入仓库',
            align: 'center',
            prop: 'in_name',
            width: '',
          },
          {
            label: '操作人',
            align: 'center',
            prop: 'add_name',
            width: '',
          },

          {
            label: '时间',
            align: 'center',
            prop: 'create_at',
            width: '',
          },
        ],
      }
    },
    mounted() {
      this.handlerwarehouse()
      this.handlerpersonnel()
      this.handlerlist()
    },
    methods: {
      handlerstorp(row) {
        postAction(this.url.stop, { id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerInquire()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlergetlist() {
        this.handlerInquire()
      },
      handlerlink(row) {
        this.$refs.info.Deinfo = true
        console.log(this.$refs.info)
        this.$refs.info.handlerinfo(row)
      },
      handlershenhe(row) {
        console.log(row, '')
        // this.$refs.add.handlercreateData(row)
        this.$refs.add.showAdd = true
      },
      handlerredo(row) {
        // this.$refs.add.handlercreateData(row)
        this.$refs.add.showAdd = true
      },
      handlerAdd() {
        this.$refs.add.showDialog = true
      },
      handlerReset() {
        this.$refs['ruleForm'].resetFields()
        this.handlerlist()
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlerInquire() {
        this.form.pageNo = 1
        this.form.pageSize = 10
        this.handlerlist()
      },
      handlePrint(row) {
        this.data_id = row.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      handlerlist() {
        postAction(this.url.list, this.form)
          .then((res) => {
            console.log(res, '')
            this.tableData = res.data
            this.total = res.totalCount
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerTiem(val) {
        console.log(val, '')
        try {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } catch (error) {}

        console.log(this.form.start_time, this.form.end_time, '')
      },
      handlerpersonnel() {
        postAction(this.url.personnel, {})
          .then((res) => {
            console.log(res, '人员')
            this.personnel = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>

<style></style>
