<template>
  <el-dialog
    title="添加商品拆装"
    :visible.sync="showDialog"
    width="1100px"
    top="5vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <el-form ref="form" class="orderTest-container" inline label-width="80px">
      <!-- 拆装前 -->
      <el-card class="before">
        <div class="tit">
          <span style="line-height: 32px; font-size: 22px; margin-right: 15px">
            拆装前
          </span>
          <el-form-item label="移出仓库" prop="">
            <el-select v-model="form.out_id" placeholder="移出仓库">
              <el-option
                v-for="(item, index) in depotSelect"
                :key="index"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="">
            <el-button type="primary" @click="handleAdd(1)">添加</el-button>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-button type="primary" @click="handleUpdate(1)">
              更新库存
            </el-button>
          </el-form-item>
        </div>
        <div class="table">
          <el-table
            stripe
            :data="beforeList"
            show-summary
            :summary-method="beforeSummary"
          >
            <!-- 序号 -->
            <el-table-column
              align="center"
              type="index"
              width="50"
            ></el-table-column>
            <el-table-column
              v-for="(item, tableIndex) in columns"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            >
              <template #default="{ $index, row }">
                <div v-if="item.label == '商品名称'">
                  <goods-search
                    ref="goodsSeachBefore"
                    :show-kouwei="false"
                    :depot-id="form.out_id"
                    :f-key="row.goods_name"
                    @add-rows="addRows($event, row, $index, 1)"
                    @select-goods-all="selectGoods($event, row, 1)"
                  ></goods-search>
                </div>
                <div v-else-if="item.label == '单位'">
                  <el-select
                    v-model="row.unit_id"
                    placeholder="单位"
                    @change="changeUnit($event, row)"
                  >
                    <el-option
                      v-for="(i, idx) in row.arr_unit"
                      :key="idx"
                      :value="i.unit_id"
                      :label="i.unit_name"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else-if="item.label == '数量'">
                  <el-input v-model="row.unit_num"></el-input>
                </div>
                <div v-else-if="item.label == '单价'">
                  <el-input v-model="row.goods_price"></el-input>
                </div>
                <div v-else-if="item.label == '金额'">
                  {{
                    (
                      Number(row.goods_price || 0) * Number(row.unit_num || 0)
                    ).toFixed(2) || 0
                  }}
                </div>
                <div v-else>{{ row[item.prop] }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="{ $index }">
                <el-button type="text" @click="handleDelete(1, $index)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <!-- 拆装后 -->
      <el-card class="after">
        <div class="tit">
          <span style="line-height: 32px; font-size: 22px; margin-right: 15px">
            拆装后
          </span>
          <el-form-item label="移入仓库" prop="">
            <el-select v-model="form.in_id" placeholder="移入仓库">
              <el-option
                v-for="(item, index) in depotSelect"
                :key="index"
                :label="item.depot_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="">
            <el-button type="primary" @click="handleAdd(2)">添加</el-button>
          </el-form-item>
          <el-form-item label="" prop="">
            <el-button type="primary" @click="handleUpdate(2)">
              更新库存
            </el-button>
          </el-form-item>
        </div>
        <div class="table">
          <el-table
            stripe
            :data="afterList"
            show-summary
            :summary-method="afterSummary"
          >
            <!-- 序号 -->
            <el-table-column
              align="center"
              type="index"
              width="50"
            ></el-table-column>
            <el-table-column
              v-for="(item, tableIndex) in columns"
              :key="tableIndex"
              :prop="item.prop"
              :label="item.label"
              :width="item.width"
              align="center"
            >
              <template #default="{ $index, row }">
                <div v-if="item.label == '商品名称'">
                  <goods-search
                    ref="goodsSeachBefore"
                    :show-kouwei="false"
                    :depot-id="form.in_id"
                    :f-key="row.goods_name"
                    @add-rows="addRows($event, row, $index, 2)"
                    @select-goods-all="selectGoods($event, row, 2)"
                  ></goods-search>
                </div>
                <div v-else-if="item.label == '单位'">
                  <el-select
                    v-model="row.unit_id"
                    placeholder="单位"
                    @change="changeUnit($event, row)"
                  >
                    <el-option
                      v-for="(i, idx) in row.arr_unit"
                      :key="idx"
                      :value="i.unit_id"
                      :label="i.unit_name"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else-if="item.label == '数量'">
                  <el-input v-model="row.unit_num"></el-input>
                </div>
                <div v-else-if="item.label == '单价'">
                  <el-input v-model="row.goods_price"></el-input>
                </div>
                <div v-else-if="item.label == '金额'">
                  {{
                    (
                      Number(row.goods_price || 0) * Number(row.unit_num || 0)
                    ).toFixed(2) || 0
                  }}
                </div>
                <div v-else>{{ row[item.prop] }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="{ $index }">
                <el-button type="text" @click="handleDelete(2, $index)">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
      <el-form-item label="拆装差额:" prop="">
        <span style="margin-right: 10px">
          {{ (afterTotal - beforeTotal).toFixed(2) }}
        </span>
        <span style="margin-right: 10px">将计入商品拆分收入</span>
        <el-tooltip
          class="item"
          effect="dark"
          content="拆装差额 = 拆装后金额合计 - 拆装前金额合计"
          placement="top"
        >
          <span>
            <i
              style="font-size: 18px; line-height: 20px"
              class="el-icon-warning"
            ></i>
          </span>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">
        提交并生成拆装出入库单
      </el-button>
      <el-button @click="showDialog = false">取消</el-button>
      <!-- <el-button @click="showDialog = false">打印</el-button> -->
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  export default {
    name: '',
    components: {
      GoodsSearch,
    },
    data() {
      return {
        showDialog: false,
        form: {},
        depotSelect: [],
        columns: [
          {
            label: '商品名称',
            prop: 'goods_name',
            width: '220',
          },
          {
            label: '规格',
            prop: 'specs',
            width: '',
          },
          {
            label: '可用库存',
            prop: 'depot_stock_num_read',
            width: '',
          },
          {
            label: '单位',
            prop: 'unit_id',
            width: '',
          },
          {
            label: '数量',
            prop: 'unit_num',
            width: '',
          },
          {
            label: '单价',
            prop: 'goods_price',
            width: '',
          },
          {
            label: '单位换算',
            prop: 'unit_cv',
            width: '',
          },

          {
            label: '金额',
            prop: 'menoy',
            width: '',
          },
        ],
        beforeList: [],
        afterList: [],
      }
    },
    computed: {
      beforeTotal() {
        let total = 0
        this.beforeList.forEach((i) => {
          if (i.goods_id > 0) {
            total += Number(i.goods_price) * Number(i.unit_num)
          }
        })
        return total.toFixed(2)
      },
      afterTotal() {
        let total = 0
        this.afterList.forEach((i) => {
          if (i.goods_id > 0) {
            total += Number(i.goods_price) * Number(i.unit_num)
          }
        })
        return total.toFixed(2)
      },
    },
    watch: {
      showDialog(v) {
        if (!v) {
          this.clearForm()
        }
      },
    },
    created() {
      this.initSelect()
    },
    mounted() {},
    methods: {
      initSelect() {
        postAction('/baseAdmin/common/depot-list', { pageSize: -1 })
          .then((res) => {
            this.depotSelect = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleAdd(type) {
        if (type == 1) {
          // 拆装前
          this.beforeList.push({})
        } else {
          // 拆装后
          this.afterList.push({})
        }
      },
      handleDelete(type, index) {
        if (type == 1) {
          // 删除拆装前
          this.beforeList.splice(index, 1)
        } else {
          // 删除拆装后
          this.afterList.splice(index, 1)
        }
      },
      async handleUpdate(type) {
        if (type == 1) {
          let num = 0
          let l = this.beforeList.length
          if (l > 0) {
            this.beforeList.forEach((i) => {
              if (i.goods_id > 0) {
                postAction('/baseAdmin/goods/window-view-do', {
                  pageSize: -1,
                  ids: [i.goods_id],
                  depot_id: this.form.out_id,
                }).then((r) => {
                  i.depot_stock_num_read = r.data[0].depot_stock_num_read
                  num++
                  if (l == num) {
                    this.$message.success('库存更新成功')
                  }
                })
              }
            })

            // this.beforeList = data
          } else {
            this.$message.error('请先选择拆装前商品')
          }
        } else {
          let num = 0
          let l = this.afterList.length
          if (l > 0) {
            this.afterList.forEach((i) => {
              if (i.goods_id > 0) {
                postAction('/baseAdmin/goods/window-view-do', {
                  pageSize: -1,
                  ids: [i.goods_id],
                  depot_id: this.form.in_id,
                }).then((r) => {
                  i.depot_stock_num_read = r.data[0].depot_stock_num_read
                  num++
                  if (l == num) {
                    this.$message.success('库存更新成功')
                  }
                })
              }
            })
          } else {
            this.$message.error('请先选择拆装后商品')
          }
        }
      },
      selectGoods(v, row, type) {
        if (v.goods_id > 0) {
          Object.keys(v).forEach((key) => {
            this.$set(row, key, v[key])
          })
          this.$set(row, 'unit_num', '')
          this.$set(row, 'type', type)
          row.goods_price = row.arr_unit.filter(
            (i) => i.unit_id == row.unit_id
          )[0].old_sell_price
        }
      },
      changeUnit(v, row) {
        let p = row.arr_unit.filter((u) => u.unit_id == v)[0]
        p = p.old_sell_price
        row.goods_price = p
      },
      addRows(v, row, index, type) {
        console.log(v, index, type)
        let this_ = this
        if (v) {
          v.forEach((i) => {
            i.unit_num = ''
            i.goods_price = i.arr_unit.filter(
              (r) => r.unit_id == i.unit_id
            )[0].old_sell_price
            if (type == 1) {
              // this_.beforeList.push({ ...i, ...{ type } })
              this_.beforeList.splice(index, 0, { ...i, ...{ type } })
            } else {
              // this_.afterList.push({ ...i, ...{ type } })
              this_.afterList.splice(index, 0, { ...i, ...{ type } })
            }
          })
        }
      },
      async handleSubmit() {
        let goods_data = []
        let beforeList = JSON.parse(JSON.stringify(this.beforeList))
        let afterList = JSON.parse(JSON.stringify(this.afterList))
        afterList.forEach((i) => {
          if (i.goods_id > 0) {
            beforeList.push(i)
          }
        })
        beforeList.forEach((i) => {
          if (i.goods_id > 0) {
            goods_data.push({
              goods_id: i.goods_id,
              goods_price: i.goods_price,
              unit_id: i.unit_id,
              unit_num: i.unit_num,
              type: i.type,
            })
          }
        })
        let ed = {
          out_id: this.form.out_id,
          in_id: this.form.in_id,
          remark: this.form.remark,
          goods_data,
        }

        console.log('ed', ed)

        let { data } = await postAction('/depotAdmin/split/add', ed)
        this.$emit('refresh')
        this.$message.success('添加成功')
        this.showDialog = false
      },
      beforeSummary({ columns, data }) {
        let sums = []
        columns.forEach((i, index) => {
          if (index === 0) {
            sums[index] = '合计'
            return
          } else if (index == 8) {
            sums[index] = this.beforeTotal
          }
        })
        return sums
      },
      afterSummary({ columns, data }) {
        let sums = []
        columns.forEach((i, index) => {
          if (index === 0) {
            sums[index] = '合计'
            return
          } else if (index == 8) {
            sums[index] = this.afterTotal
          }
        })
        return sums
      },
      clearForm() {
        this.beforeList = []
        this.afterList = []
        this.form = {}
      },
    },
  }
</script>
<style lang="scss" scoped>
  .table {
    margin: 0 0 15px;
  }
</style>
