var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 22 } },
            [
              _c(
                "el-form",
                { ref: "ruleForm", attrs: { inline: "", model: _vm.form } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "out_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "移出仓库" },
                          model: {
                            value: _vm.form.out_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "out_id", $$v)
                            },
                            expression: "form.out_id",
                          },
                        },
                        _vm._l(_vm.warehouse, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.depot_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "in_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "移入仓库" },
                          model: {
                            value: _vm.form.in_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "in_id", $$v)
                            },
                            expression: "form.in_id",
                          },
                        },
                        _vm._l(_vm.warehouse, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.depot_name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "add_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "120px" },
                          attrs: { placeholder: "选择操作人" },
                          model: {
                            value: _vm.form.add_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "add_id", $$v)
                            },
                            expression: "form.add_id",
                          },
                        },
                        _vm._l(_vm.personnel, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.user_name,
                              value: item.user_id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "value1" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "220px" },
                        attrs: {
                          type: "datetimerange",
                          "start-placeholder": "起始时间",
                          "end-placeholder": "结束时间",
                          "default-time": ["12:00:00"],
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        on: { change: _vm.handlerTiem },
                        model: {
                          value: _vm.value1,
                          callback: function ($$v) {
                            _vm.value1 = $$v
                          },
                          expression: "value1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "goods_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px" },
                        attrs: {
                          placeholder: "商品名称，简拼",
                          "suffix-icon": "el-icon-search",
                        },
                        model: {
                          value: _vm.form.goods_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "goods_name", $$v)
                          },
                          expression: "form.goods_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerInquire },
                        },
                        [_vm._v("查 询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "info" },
                          on: { click: _vm.handlerReset },
                        },
                        [_vm._v("重 置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-form",
                { attrs: { inline: "" } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handlerAdd },
                        },
                        [_vm._v(" 添加商品拆装 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.tableData } },
            [
              _c("el-table-column", { attrs: { type: "index" } }),
              _vm._l(_vm.colemd, function (list, index) {
                return _c("el-table-column", {
                  key: index,
                  attrs: {
                    label: list.label,
                    align: list.align,
                    prop: list.prop,
                    width: "",
                  },
                })
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  width: "200",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlerlink(row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-popconfirm",
                          {
                            attrs: { title: "您真的要作废这个拆装商品吗？" },
                            on: {
                              confirm: function ($event) {
                                return _vm.handlerstorp(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: row.status != 5,
                                    expression: "row.status != 5",
                                  },
                                ],
                                staticStyle: { "margin-left": "10px" },
                                attrs: { slot: "reference", type: "text" },
                                slot: "reference",
                              },
                              [_vm._v(" 作废 ")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePrint(row)
                              },
                            },
                          },
                          [_vm._v(" 打印 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg", attrs: { type: "flex", justify: "end" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.form.pageNo,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.form.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("info-details", { ref: "info" }),
      _c("add-disassembly", {
        ref: "add",
        on: { refresh: _vm.handlergetlist, getlist: _vm.handlergetlist },
      }),
      _c("ruilang-display-dialog", {
        ref: "RuilangDisplayDialog",
        attrs: { bill_type: "CZ", data_id: _vm.data_id, type: 1 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }